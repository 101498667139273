<template>
    <img 
        v-if="imgUrl"
        :src="imgUrl" 
        loading="lazy" 
        @error="onError"
    />
    <img
        v-else
        src="@/assets/logo.png"
        loading="lazy"
    />
    
</template>
<script>
import {ref} from 'vue'
import {API} from '@/api/API'
import { ApiEndPoints } from '@/api/Endpoints'
export default {
    props:{
        url:{
            type:String,
            default:''
        },
        id:{
            type:[String, Number],
            default:''
        },
        socialName:{
            type:String,
            default:'instagram'
        },
        fallbackImage:{
            type:Boolean,
            default:false
        }
    },
    setup(props){
        const imgUrl = ref(props.url)
        const onError = async() => {
           
            try {
                const {data} = await API.get(ApiEndPoints.HELPER+`/${props.socialName}/post/${props.id}/update`)
                imgUrl.value = data.fullPicture
            } catch (error) {
                //console.log(error.message)
                if(props.fallbackImage){
                    imgUrl.value = false
                }   
            }
        }
        return {
            imgUrl,
            onError
        }
    }
}
</script>