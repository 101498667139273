<template>
    <p v-if="!sdk">no sdk</p>
    <FacebookLoginButton
        v-else-if="!fbUser || forceLogin"
            @fb-login="onFacebookLog"
            @fb-no-login="onFacebookNoLog"
        :message="message"
    ></FacebookLoginButton>
    <slot v-else></slot>
</template>
<script>
import { ref, onMounted } from 'vue'
import FacebookLoginButton from './FacebookLoginButton'
import{getFacebookSdk, loadFacebookSDK, checkLoginStatus } from './../../../utils/FacebookUtils'
export default {
    emits:['fb-login','fb-no-login'],
    props:{
        fbUser:{
            type:Object,
            default:()=>{}
        },
        forceLogin:{
            type:Boolean,
            default:false
        },
        message:{
           type:String,
            default: 'Login with Facebook'
        }
    },
    components:{
        FacebookLoginButton
    },
    setup(props, {emit}){
        const sdk = ref(getFacebookSdk())
        
        onMounted(async()=>{
            if(!sdk.value){
                await loadFacebookSDK()
                sdk.value = getFacebookSdk()
            }
            try {
                const { authResponse } = await checkLoginStatus()
                if(!authResponse){
                    emit('fb-no-login', null)
                }
            } catch (error) {
                
                emit('fb-no-login', null)
            }
        })
        const onFacebookLog = async (response) => {
            const {authResponse} = response
            if(!authResponse.alreadyLogged)
                emit('fb-login', authResponse)
        }
        const onFacebookNoLog = (response) => {
           emit('fb-no-login', response)
        }
        return{
            sdk,
            onFacebookLog,
            onFacebookNoLog
        }
    }
}
</script>