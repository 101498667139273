<template>
    <img 
        :src="imgUrl" 
        loading="lazy" 
        @error="onError"
    />
</template>
<script>
import {ref} from 'vue'
import {API} from '@/api/API'
import { ApiEndPoints } from '@/api/Endpoints'
export default {
    props:{
        url:{
            type:String,
            default:''
        },
        id:{
            type:[String, Number],
            default:''
        },
    },
    setup(props){
        const imgUrl = ref(props.url)
        const onError = async() => {
           
            try {
                const {data} = await API.get(ApiEndPoints.FACEBOOK_HELPER+`/post/${props.id}/update`)
                imgUrl.value = data.fullPicture
            } catch (error) {
                console.log(error.message)
            }
            

        }
        return {
            imgUrl,
            onError
        }

    }
}
</script>