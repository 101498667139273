import {store} from '@/store/store'

export const acceptSocial = async (social, campaign)=>{
            
    try {
      await store.dispatch('campaigns/accept', {
          socialId:social.socialId, 
          campaignId:campaign.id,
          accepted:'Accepted'
      }) 
      const sc = campaign.socialCampaigns
      if(sc){
          sc.find(s=>s.socialId === social.socialId).authorized = true
        
      }
    } catch (error) {
       await store.dispatch('messages/message', {
           title:"errore durante l'accettazione del social", 
           message:error.message 
       })
   }
}
export const unacceptSocial = async (social, campaign)=>{
   
    try {
      await store.dispatch('campaigns/accept', {
          socialId:social.socialId, 
          campaignId:campaign.id,
          accepted:'Declined'
      }) 
      const sc = campaign.socialCampaigns
      if(sc){
          sc.find(s=>s.socialId === social.socialId).authorized = false
        
      }
    } catch (error) {
       await store.dispatch('messages/message', {
           title:"errore durante il rifiuto del social", 
           message:error.message 
       })
   }
}
export const authorizeSocial = async (social, campaign)=>{
            
    try {
      await store.dispatch('campaigns/authorize', {
          socialId:social.socialId, 
          campaignId:campaign.id,
          authorized:true
      }) 

      const sc = campaign.socialCampaigns
      if (sc) {
        sc.find(s=>s.socialId === social.socialId).authorized = false
      }
     
    } catch (error) {
       await store.dispatch('messages/message', {
           title:"errore durante l'autorizzazione del social", 
           message:error.message 
       })
   }
}
export const deAuthorizeSocial = async (social, campaign) => {
    try {
      await store.dispatch('campaigns/authorize', {
        socialId:social.socialId, 
        campaignId:campaign.id,
        authorized:false
      }) 

      const sc = campaign.socialCampaigns
      
      if (sc) {
        sc.find(s=>s.socialId === social.socialId).authorized = false 
      }

    } catch (error) {
        await store.dispatch('messages/message', {
           title:"errore durante la deautorizzazione del social", 
           message:error.message 
       })
    }
}
export const saveCampaign = async(data, campaign)=>{
    try {
        const response  = await store.dispatch('campaigns/update', {
            id:campaign.id,
            data:data, 
            socialCampaigns:campaign.socialCampaigns, 
            postCampaigns:campaign.postCampaigns,
            campaignTags:campaign.campaignTags
        })
        await store.dispatch('messages/message', {
            title: campaign.title,
            message:'la campagna è stata modificata correttamente'
        })
        return response
    } catch (error){
        await store.dispatch('messages/message', {
            title:'errore nella modifica della campagna', 
            message:error.message 
        })
        return null
    }
    
}


export const unassignFacebookAdvertPost = async( post ) => {
    try {
        await store.dispatch('post/removeFacebookPost', post.postId)
        await store.dispatch('messages/message', {
            title: 'post eliminato',
            message:'il post è stato eliminato correttamente'
        })
    } catch (error) {
        await store.dispatch('messages/message', {
            title:"errore nell'elininazione del post",
            message:error.message 
        })
    }
}


export const openLocation = (url) => {
    window.open(url)
}